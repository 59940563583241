.tagcontainer {
  margin-top: 3px;
  margin-left: 1px;
  margin-right: 1px;
  height: 45px;
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.tagname {
  font-family: 'Courier New', Courier, monospace;
  font-size: smaller;
  color: greenyellow;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  overflow: hidden;
  inline-size: 100%;
}

.tagvalue {
  font-family: 'Courier New', Courier, monospace;
  font-size: small;
  font-weight: bold;
  color: red;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.blink_me2 {
  animation: blinker2 1s linear infinite;
}

@keyframes blinker2 {
  50% {
    opacity: 0;
  }
}