.bstname {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bstrow {
    margin-bottom: 5px;
}
