.cookingrow {
    background-color: rgb(170, 170, 170);
    padding-top: 10px;
}

.cookingcontainer {
    display: flex;
}


